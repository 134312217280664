/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodos = /* GraphQL */ `
  query GetTodos {
    getTodos {
      id
      name
      description
      priority
    }
  }
`;
export const getTodo2 = /* GraphQL */ `
  query GetTodo2($id: ID!) {
    getTodo2(id: $id) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const listTodo2s = /* GraphQL */ `
  query ListTodo2s(
    $filter: ModelTodo2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodo2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        priority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasPasien = /* GraphQL */ `
  query GetMasPasien($id: ID!) {
    getMasPasien(id: $id) {
      id
      pasid
      pasrm
      pasnama
      pasemail
      katid
      pasgoldarah
      pasjk
      pasalamat
      paskota
      pastelp
      paspekerjaan
      passtatusnikah
      pasnokk
      pasnoktp
      pasibu
      pasayah
      pastglreg
      pastgllahir
      pasaktif
      pasalergi
      pasbbm
      androidid
      pasparentid
      idvmedis
      createdAt
      updatedAt
    }
  }
`;
export const listMasPasiens = /* GraphQL */ `
  query ListMasPasiens(
    $filter: ModelmasPasienFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMasPasiens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pasid
        pasrm
        pasnama
        pasemail
        katid
        pasgoldarah
        pasjk
        pasalamat
        paskota
        pastelp
        paspekerjaan
        passtatusnikah
        pasnokk
        pasnoktp
        pasibu
        pasayah
        pastglreg
        pastgllahir
        pasaktif
        pasalergi
        pasbbm
        androidid
        pasparentid
        idvmedis
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasDokter = /* GraphQL */ `
  query GetMasDokter($id: ID!) {
    getMasDokter(id: $id) {
      id
      dokid
      doknama
      dokspesialis
      dokalamat
      dokkota
      doktelp
      dokemail
      dokaktif
      dokjenis
      doktglreg
      doktglmulai
      dokjasa
      dokfoto
      dokidgen
      doknostr
      createdAt
      updatedAt
    }
  }
`;
export const listMasDokters = /* GraphQL */ `
  query ListMasDokters(
    $filter: ModelmasDokterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMasDokters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dokid
        doknama
        dokspesialis
        dokalamat
        dokkota
        doktelp
        dokemail
        dokaktif
        dokjenis
        doktglreg
        doktglmulai
        dokjasa
        dokfoto
        dokidgen
        doknostr
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasPoli = /* GraphQL */ `
  query GetMasPoli($id: ID!) {
    getMasPoli(id: $id) {
      id
      polid
      polnama
      polket
      polaktif
      createdAt
      updatedAt
    }
  }
`;
export const listMasPolis = /* GraphQL */ `
  query ListMasPolis(
    $filter: ModelmasPoliFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMasPolis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        polid
        polnama
        polket
        polaktif
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIncrementId = /* GraphQL */ `
  query GetIncrementId($id: ID!) {
    getIncrementId(id: $id) {
      id
      name
      code
      refid
      createdAt
      updatedAt
    }
  }
`;
export const listIncrementIds = /* GraphQL */ `
  query ListIncrementIds(
    $id: ID
    $filter: ModelIncrementIdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIncrementIds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        code
        refid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceId = /* GraphQL */ `
  query GetDeviceId($id: ID!) {
    getDeviceId(id: $id) {
      id
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceIds = /* GraphQL */ `
  query ListDeviceIds(
    $id: ID
    $filter: ModelDeviceIdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeviceIds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblVersi = /* GraphQL */ `
  query GetTblVersi($id: ID!) {
    getTblVersi(id: $id) {
      id
      version
      verstatus
      vername
      vernote
      createdAt
      updatedAt
    }
  }
`;
export const listTblVersis = /* GraphQL */ `
  query ListTblVersis(
    $id: ID
    $filter: ModelTblVersiFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblVersis(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        verstatus
        vername
        vernote
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblGroup = /* GraphQL */ `
  query GetTblGroup($keypinID: String!) {
    getTblGroup(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tgrpid
      tgrpname
      tgrpdisc
      createdAt
      updatedAt
    }
  }
`;
export const listTblGroups = /* GraphQL */ `
  query ListTblGroups(
    $keypinID: String
    $filter: ModelTblGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblGroups(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblGroupAccess = /* GraphQL */ `
  query GetTblGroupAccess($keypinID: String!) {
    getTblGroupAccess(keypinID: $keypinID) {
      id
      TblGroupID
      TblGroup {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      keypinID
      keypin
      tgacid
      tgrpid
      tgrpmenu
      createdAt
      updatedAt
    }
  }
`;
export const listTblGroupAccesses = /* GraphQL */ `
  query ListTblGroupAccesses(
    $keypinID: String
    $filter: ModelTblGroupAccessFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblGroupAccesses(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        TblGroupID
        TblGroup {
          id
          keypinID
          keypin
          tgrpid
          tgrpname
          tgrpdisc
          createdAt
          updatedAt
        }
        keypinID
        keypin
        tgacid
        tgrpid
        tgrpmenu
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGetToken = /* GraphQL */ `
  query GetGetToken($id: ID!) {
    getGetToken(id: $id) {
      id
      app_id
      nama_lengkap
      username
      password
      password_hash
      domain
      user_id
      kl_id
      kl_nama
      alamat
      keterangan
      status
      createdAt
      updatedAt
    }
  }
`;
export const listGetTokens = /* GraphQL */ `
  query ListGetTokens(
    $filter: ModelgetTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGetTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        app_id
        nama_lengkap
        username
        password
        password_hash
        domain
        user_id
        kl_id
        kl_nama
        alamat
        keterangan
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblIdentity = /* GraphQL */ `
  query GetTblIdentity($keypinID: String!) {
    getTblIdentity(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tidaponame
      tidapono
      tidapoadd
      tidapocity
      tidapophone
      tidapomail
      tidapoweb
      tidapoimg
      tidaporegion
      createdAt
      updatedAt
    }
  }
`;
export const listTblIdentities = /* GraphQL */ `
  query ListTblIdentities(
    $keypinID: String
    $filter: ModelTblIdentityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblIdentities(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tidaponame
        tidapono
        tidapoadd
        tidapocity
        tidapophone
        tidapomail
        tidapoweb
        tidapoimg
        tidaporegion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblLogin = /* GraphQL */ `
  query GetTblLogin($keypinID: String!) {
    getTblLogin(keypinID: $keypinID) {
      id
      keypinID
      tlogid
      keypin
      tlogsub
      tloguser
      tlogauth
      tlogname
      tlogimg
      tlogregion
      tgrpid
      createdAt
      updatedAt
    }
  }
`;
export const listTblLogins = /* GraphQL */ `
  query ListTblLogins(
    $keypinID: String
    $filter: ModelTblLoginFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblLogins(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        tlogid
        keypin
        tlogsub
        tloguser
        tlogauth
        tlogname
        tlogimg
        tlogregion
        tgrpid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblJadShf = /* GraphQL */ `
  query GetTblJadShf($keypinID: String!) {
    getTblJadShf(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tjsnama
      tjsid
      createdAt
      updatedAt
    }
  }
`;
export const listTblJadShfs = /* GraphQL */ `
  query ListTblJadShfs(
    $keypinID: String
    $filter: ModelTblJadShfFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblJadShfs(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tjsnama
        tjsid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblShf = /* GraphQL */ `
  query GetTblShf($id: ID!) {
    getTblShf(id: $id) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const listTblShfs = /* GraphQL */ `
  query ListTblShfs(
    $filter: ModelTblShfFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTblShfs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keypinID
        keypin
        tshid
        tshopen
        tshclose
        tshopenbal
        tshclosebal
        tshnote
        tlogid
        tshcloselogid
        tshclosename
        tshcode
        tshsumsales
        tshsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblShif = /* GraphQL */ `
  query GetTblShif($keypinID: String!) {
    getTblShif(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const listTblShifs = /* GraphQL */ `
  query ListTblShifs(
    $keypinID: String
    $filter: ModelTblShifFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblShifs(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tshid
        tshopen
        tshclose
        tshopenbal
        tshclosebal
        tshnote
        tlogid
        tshcloselogid
        tshclosename
        tshcode
        tshsumsales
        tshsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblAppConfig = /* GraphQL */ `
  query GetTblAppConfig($keypinID: String!) {
    getTblAppConfig(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tacaptmemo
      tactax
      tacembalase
      tactuslah
      tacmsh
      tacetiket
      createdAt
      updatedAt
    }
  }
`;
export const listTblAppConfigs = /* GraphQL */ `
  query ListTblAppConfigs(
    $keypinID: String
    $filter: ModelTblAppConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblAppConfigs(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tacaptmemo
        tactax
        tacembalase
        tactuslah
        tacmsh
        tacetiket
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblItems = /* GraphQL */ `
  query GetTblItems($keypinID: String!) {
    getTblItems(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tiid
      ticode
      tiname
      ticategoryid
      ticategory
      tifactoryid
      tifactory
      tilocationid
      tilocation
      ticontent
      titype
      tistock
      tisatcount
      tisatid1
      tisatname1
      tisatidcon1
      tibarcode1
      tiprice11
      tipricedisc11
      tiprice12
      tipricedisc12
      tiprice13
      tipricedisc13
      tisatid2
      tisatname2
      tisatidcon2
      tibarcode2
      tiprice21
      tipricedisc21
      tiprice22
      tipricedisc22
      tiprice23
      tipricedisc23
      tisatid3
      tisatname3
      tisatidcon3
      tibarcode3
      tiprice31
      tipricedisc31
      tiprice32
      tipricedisc32
      tiprice33
      tipricedisc33
      tisatid4
      tisatname4
      tisatidcon4
      tibarcode4
      tiprice41
      tipricedisc41
      tiprice42
      tipricedisc42
      tiprice43
      tipricedisc43
      createdAt
      updatedAt
    }
  }
`;
export const listTblItems = /* GraphQL */ `
  query ListTblItems(
    $keypinID: String
    $filter: ModelTblItemsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblItems(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tiid
        ticode
        tiname
        ticategoryid
        ticategory
        tifactoryid
        tifactory
        tilocationid
        tilocation
        ticontent
        titype
        tistock
        tisatcount
        tisatid1
        tisatname1
        tisatidcon1
        tibarcode1
        tiprice11
        tipricedisc11
        tiprice12
        tipricedisc12
        tiprice13
        tipricedisc13
        tisatid2
        tisatname2
        tisatidcon2
        tibarcode2
        tiprice21
        tipricedisc21
        tiprice22
        tipricedisc22
        tiprice23
        tipricedisc23
        tisatid3
        tisatname3
        tisatidcon3
        tibarcode3
        tiprice31
        tipricedisc31
        tiprice32
        tipricedisc32
        tiprice33
        tipricedisc33
        tisatid4
        tisatname4
        tisatidcon4
        tibarcode4
        tiprice41
        tipricedisc41
        tiprice42
        tipricedisc42
        tiprice43
        tipricedisc43
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLoginWebjs = /* GraphQL */ `
  query GetLoginWebjs($id: ID!) {
    getLoginWebjs(id: $id) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const listLoginWebjs = /* GraphQL */ `
  query ListLoginWebjs(
    $filter: ModelloginWebjsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoginWebjs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        statusCode
        body
        events
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLoginWebjs3 = /* GraphQL */ `
  query GetLoginWebjs3($id: ID!) {
    getLoginWebjs3(id: $id) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const listLoginWebjs3s = /* GraphQL */ `
  query ListLoginWebjs3s(
    $filter: ModelloginWebjs3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoginWebjs3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        statusCode
        body
        events
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLoginWebjs4 = /* GraphQL */ `
  query GetLoginWebjs4($id: ID!) {
    getLoginWebjs4(id: $id) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const listLoginWebjs4s = /* GraphQL */ `
  query ListLoginWebjs4s(
    $filter: ModelloginWebjs4FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoginWebjs4s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        statusCode
        body
        events
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblSync = /* GraphQL */ `
  query GetTblSync($keypinID: String!) {
    getTblSync(keypinID: $keypinID) {
      id
      keypin
      keypinID
      initdownload
      statusdownload
      errormsgdownload
      tsylastdownload
      tsylastdownloadid
      initupload
      statusupload
      errormsgupload
      tsylastupload
      tsylastuploadid
      flaglocalid
      createdAt
      updatedAt
    }
  }
`;
export const listTblSyncs = /* GraphQL */ `
  query ListTblSyncs(
    $keypinID: String
    $filter: ModelTblSyncFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblSyncs(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypin
        keypinID
        initdownload
        statusdownload
        errormsgdownload
        tsylastdownload
        tsylastdownloadid
        initupload
        statusupload
        errormsgupload
        tsylastupload
        tsylastuploadid
        flaglocalid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblAccount = /* GraphQL */ `
  query GetTblAccount($keypinID: String!) {
    getTblAccount(keypinID: $keypinID) {
      id
      keypinID
      keypin
      taccgroup
      taccpost
      tacccode
      taccname
      createdAt
      updatedAt
    }
  }
`;
export const listTblAccounts = /* GraphQL */ `
  query ListTblAccounts(
    $keypinID: String
    $filter: ModelTblAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblAccounts(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        taccgroup
        taccpost
        tacccode
        taccname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblDoctor = /* GraphQL */ `
  query GetTblDoctor($keypinID: String!) {
    getTblDoctor(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tdocid
      tdocname
      tdocspes
      tdocaddres
      tdocdatestart
      tdoccity
      tdocphone
      tdocemail
      tdocactive
      tdoctype
      tdocdatereg
      tdocshare
      tdocstrno
      tdocsync
      createdAt
      updatedAt
    }
  }
`;
export const listTblDoctors = /* GraphQL */ `
  query ListTblDoctors(
    $keypinID: String
    $filter: ModelTblDoctorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblDoctors(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tdocid
        tdocname
        tdocspes
        tdocaddres
        tdocdatestart
        tdoccity
        tdocphone
        tdocemail
        tdocactive
        tdoctype
        tdocdatereg
        tdocshare
        tdocstrno
        tdocsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblWareHouse = /* GraphQL */ `
  query GetTblWareHouse($keypinID: String!) {
    getTblWareHouse(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tiid
      twhid
      twhname
      twhstock
      twhlocation
      createdAt
      updatedAt
    }
  }
`;
export const listTblWareHouses = /* GraphQL */ `
  query ListTblWareHouses(
    $keypinID: String
    $filter: ModelTblWareHouseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblWareHouses(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tiid
        twhid
        twhname
        twhstock
        twhlocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblItemsPackage = /* GraphQL */ `
  query GetTblItemsPackage($keypinID: String!) {
    getTblItemsPackage(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tpid
      titpid
      tiid
      titpqty
      titpsatid
      titpsatname
      titpsatidcon
      createdAt
      updatedAt
    }
  }
`;
export const listTblItemsPackages = /* GraphQL */ `
  query ListTblItemsPackages(
    $keypinID: String
    $filter: ModelTblItemsPackageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblItemsPackages(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tpid
        titpid
        tiid
        titpqty
        titpsatid
        titpsatname
        titpsatidcon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblPatient = /* GraphQL */ `
  query GetTblPatient($keypinID: String!) {
    getTblPatient(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tpatid
      tpatcat
      tpatcode
      tpatname
      tpatgender
      tpatborn
      tpataddress
      tpatmother
      tpatphone
      tpatblood
      tpatcity
      tpatmarital
      tpatjob
      tpatfather
      tpatkkno
      tpatregno
      tpatemail
      tpataller
      tpatactive
      tpatsync
      createdAt
      updatedAt
    }
  }
`;
export const listTblPatients = /* GraphQL */ `
  query ListTblPatients(
    $keypinID: String
    $filter: ModelTblPatientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblPatients(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tpatid
        tpatcat
        tpatcode
        tpatname
        tpatgender
        tpatborn
        tpataddress
        tpatmother
        tpatphone
        tpatblood
        tpatcity
        tpatmarital
        tpatjob
        tpatfather
        tpatkkno
        tpatregno
        tpatemail
        tpataller
        tpatactive
        tpatsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblCategory = /* GraphQL */ `
  query GetTblCategory($keypinID: String!) {
    getTblCategory(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tcid
      tcname
      tcrate
      tcnote
      tcactive
      createdAt
      updatedAt
    }
  }
`;
export const listTblCategories = /* GraphQL */ `
  query ListTblCategories(
    $keypinID: String
    $filter: ModelTblCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblCategories(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tcid
        tcname
        tcrate
        tcnote
        tcactive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblCashierUsers = /* GraphQL */ `
  query GetTblCashierUsers($keypinID: String!) {
    getTblCashierUsers(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tcuid
      tcuname
      createdAt
      updatedAt
    }
  }
`;
export const listTblCashierUsers = /* GraphQL */ `
  query ListTblCashierUsers(
    $keypinID: String
    $filter: ModelTblCashierUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblCashierUsers(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tcuid
        tcuname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblUserConfig = /* GraphQL */ `
  query GetTblUserConfig($keypinID: String!) {
    getTblUserConfig(keypinID: $keypinID) {
      id
      keypinID
      keypin
      tucid
      tlogid
      tucprinter
      tucleft1
      tucleft2
      tucleft3
      tucleft4
      tucleft5
      tucleft6
      tucleft7
      tucleft8
      tucleft9
      tucleft10
      tucleft11
      tucleft12
      tucleft13
      tucright1
      tucright2
      tucright3
      tucright4
      tucright5
      tucright6
      tucright7
      tucright8
      tucright9
      tucright10
      tucright11
      tucright12
      tucright13
      tucpromo1
      tucpromo2
      tucpromo3
      tucpromo4
      tucpromo5
      tucpromo6
      tucpromo7
      tucpromo8
      tucpromo9
      tucpromo10
      tucpromo13
      tucexpired1
      tucexpired2
      tucexpired3
      tucexpired4
      tucexpired5
      tucexpired6
      tucexpired7
      tucexpired8
      tucexpired9
      tucexpired10
      tucexpired13
      tucdetail1
      tucdetail2
      tucdetail6
      tucdetail7
      createdAt
      updatedAt
    }
  }
`;
export const listTblUserConfigs = /* GraphQL */ `
  query ListTblUserConfigs(
    $keypinID: String
    $filter: ModelTblUserConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblUserConfigs(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keypinID
        keypin
        tucid
        tlogid
        tucprinter
        tucleft1
        tucleft2
        tucleft3
        tucleft4
        tucleft5
        tucleft6
        tucleft7
        tucleft8
        tucleft9
        tucleft10
        tucleft11
        tucleft12
        tucleft13
        tucright1
        tucright2
        tucright3
        tucright4
        tucright5
        tucright6
        tucright7
        tucright8
        tucright9
        tucright10
        tucright11
        tucright12
        tucright13
        tucpromo1
        tucpromo2
        tucpromo3
        tucpromo4
        tucpromo5
        tucpromo6
        tucpromo7
        tucpromo8
        tucpromo9
        tucpromo10
        tucpromo13
        tucexpired1
        tucexpired2
        tucexpired3
        tucexpired4
        tucexpired5
        tucexpired6
        tucexpired7
        tucexpired8
        tucexpired9
        tucexpired10
        tucexpired13
        tucdetail1
        tucdetail2
        tucdetail6
        tucdetail7
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblItemSale = /* GraphQL */ `
  query GetTblItemSale($keypinID: String!) {
    getTblItemSale(keypinID: $keypinID) {
      keypinID
      keypin
      tisid
      tiud
      tpatid
      tisrecipe
      tdocid
      tisdate
      tisnoinvoice
      tispredisc
      tisdisc
      tisdp
      tiscard
      tiscardcash
      tiscash
      tischange
      tistype
      tisnote
      tisactive
      tistaxinvoice
      tisdeadline
      tisday
      tispretax
      tistax
      tiscategoryid
      tiscost
      tshid
      tispay
      tissync
      tisbank
      tisbankid
      tistotal
      createdAt
      updatedAt
    }
  }
`;
export const listTblItemSales = /* GraphQL */ `
  query ListTblItemSales(
    $keypinID: String
    $filter: ModelTblItemSaleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblItemSales(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        keypinID
        keypin
        tisid
        tiud
        tpatid
        tisrecipe
        tdocid
        tisdate
        tisnoinvoice
        tispredisc
        tisdisc
        tisdp
        tiscard
        tiscardcash
        tiscash
        tischange
        tistype
        tisnote
        tisactive
        tistaxinvoice
        tisdeadline
        tisday
        tispretax
        tistax
        tiscategoryid
        tiscost
        tshid
        tispay
        tissync
        tisbank
        tisbankid
        tistotal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTblItemSaleDetail = /* GraphQL */ `
  query GetTblItemSaleDetail($keypinID: String!) {
    getTblItemSaleDetail(keypinID: $keypinID) {
      keypinID
      keypin
      tisdid
      tisid
      tiid
      tisdsoid
      tisdcode
      tisdtype
      tisdsatnama
      tisdqty
      tisdconvert
      tisdhb
      tisdhpp
      tisdhj
      tisdsubtotal
      tisdpredisc
      tisddisc
      tisdtotal
      tisdpackage
      tisdrecipe
      tisdtuslah
      tisdemba
      tisdnote
      tisdflax
      tisdflaxr
      tisdpriceid
      tisdpoint
      tisdname
      twhid
      tisdpricetype
      tisdsync
      createdAt
      updatedAt
    }
  }
`;
export const listTblItemSaleDetails = /* GraphQL */ `
  query ListTblItemSaleDetails(
    $keypinID: String
    $filter: ModelTblItemSaleDetailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTblItemSaleDetails(
      keypinID: $keypinID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        keypinID
        keypin
        tisdid
        tisid
        tiid
        tisdsoid
        tisdcode
        tisdtype
        tisdsatnama
        tisdqty
        tisdconvert
        tisdhb
        tisdhpp
        tisdhj
        tisdsubtotal
        tisdpredisc
        tisddisc
        tisdtotal
        tisdpackage
        tisdrecipe
        tisdtuslah
        tisdemba
        tisdnote
        tisdflax
        tisdflaxr
        tisdpriceid
        tisdpoint
        tisdname
        twhid
        tisdpricetype
        tisdsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasObat = /* GraphQL */ `
  query GetMasObat($id: ID!) {
    getMasObat(id: $id) {
      id
      obatid
      pabid
      katoid
      lokid
      obatkode
      obatnama
      obatjenis
      obatkandungan
      obatetiket
      obatsyarat
      createdAt
      updatedAt
    }
  }
`;
export const listMasObats = /* GraphQL */ `
  query ListMasObats(
    $filter: ModelmasObatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMasObats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        obatid
        pabid
        katoid
        lokid
        obatkode
        obatnama
        obatjenis
        obatkandungan
        obatetiket
        obatsyarat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblGroup = /* GraphQL */ `
  query KeypinTblGroup(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblGroup(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tgrpidTblGroup = /* GraphQL */ `
  query TgrpidTblGroup(
    $tgrpid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tgrpidTblGroup(
      tgrpid: $tgrpid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblGroupAccess = /* GraphQL */ `
  query KeypinTblGroupAccess(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblGroupAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblGroupAccess(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TblGroupID
        TblGroup {
          id
          keypinID
          keypin
          tgrpid
          tgrpname
          tgrpdisc
          createdAt
          updatedAt
        }
        keypinID
        keypin
        tgacid
        tgrpid
        tgrpmenu
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tgacidTblGroupAccess = /* GraphQL */ `
  query TgacidTblGroupAccess(
    $tgacid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblGroupAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tgacidTblGroupAccess(
      tgacid: $tgacid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TblGroupID
        TblGroup {
          id
          keypinID
          keypin
          tgrpid
          tgrpname
          tgrpdisc
          createdAt
          updatedAt
        }
        keypinID
        keypin
        tgacid
        tgrpid
        tgrpmenu
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblLogin = /* GraphQL */ `
  query KeypinTblLogin(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblLoginFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblLogin(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        tlogid
        keypin
        tlogsub
        tloguser
        tlogauth
        tlogname
        tlogimg
        tlogregion
        tgrpid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblJadShf = /* GraphQL */ `
  query KeypinTblJadShf(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblJadShfFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblJadShf(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tjsnama
        tjsid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tjsidTblJadShf = /* GraphQL */ `
  query TjsidTblJadShf(
    $tjsid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblJadShfFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tjsidTblJadShf(
      tjsid: $tjsid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tjsnama
        tjsid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblShf = /* GraphQL */ `
  query KeypinTblShf(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblShfFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblShf(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tshid
        tshopen
        tshclose
        tshopenbal
        tshclosebal
        tshnote
        tlogid
        tshcloselogid
        tshclosename
        tshcode
        tshsumsales
        tshsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblShif = /* GraphQL */ `
  query KeypinTblShif(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblShifFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblShif(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tshid
        tshopen
        tshclose
        tshopenbal
        tshclosebal
        tshnote
        tlogid
        tshcloselogid
        tshclosename
        tshcode
        tshsumsales
        tshsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblAppConfig = /* GraphQL */ `
  query KeypinTblAppConfig(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblAppConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblAppConfig(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tacaptmemo
        tactax
        tacembalase
        tactuslah
        tacmsh
        tacetiket
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblItems = /* GraphQL */ `
  query KeypinTblItems(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblItems(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tiid
        ticode
        tiname
        ticategoryid
        ticategory
        tifactoryid
        tifactory
        tilocationid
        tilocation
        ticontent
        titype
        tistock
        tisatcount
        tisatid1
        tisatname1
        tisatidcon1
        tibarcode1
        tiprice11
        tipricedisc11
        tiprice12
        tipricedisc12
        tiprice13
        tipricedisc13
        tisatid2
        tisatname2
        tisatidcon2
        tibarcode2
        tiprice21
        tipricedisc21
        tiprice22
        tipricedisc22
        tiprice23
        tipricedisc23
        tisatid3
        tisatname3
        tisatidcon3
        tibarcode3
        tiprice31
        tipricedisc31
        tiprice32
        tipricedisc32
        tiprice33
        tipricedisc33
        tisatid4
        tisatname4
        tisatidcon4
        tibarcode4
        tiprice41
        tipricedisc41
        tiprice42
        tipricedisc42
        tiprice43
        tipricedisc43
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tiidTblItems = /* GraphQL */ `
  query TiidTblItems(
    $tiid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tiidTblItems(
      tiid: $tiid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tiid
        ticode
        tiname
        ticategoryid
        ticategory
        tifactoryid
        tifactory
        tilocationid
        tilocation
        ticontent
        titype
        tistock
        tisatcount
        tisatid1
        tisatname1
        tisatidcon1
        tibarcode1
        tiprice11
        tipricedisc11
        tiprice12
        tipricedisc12
        tiprice13
        tipricedisc13
        tisatid2
        tisatname2
        tisatidcon2
        tibarcode2
        tiprice21
        tipricedisc21
        tiprice22
        tipricedisc22
        tiprice23
        tipricedisc23
        tisatid3
        tisatname3
        tisatidcon3
        tibarcode3
        tiprice31
        tipricedisc31
        tiprice32
        tipricedisc32
        tiprice33
        tipricedisc33
        tisatid4
        tisatname4
        tisatidcon4
        tibarcode4
        tiprice41
        tipricedisc41
        tiprice42
        tipricedisc42
        tiprice43
        tipricedisc43
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblAccount = /* GraphQL */ `
  query KeypinTblAccount(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblAccount(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        taccgroup
        taccpost
        tacccode
        taccname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tacccodeTblAccount = /* GraphQL */ `
  query TacccodeTblAccount(
    $tacccode: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tacccodeTblAccount(
      tacccode: $tacccode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        taccgroup
        taccpost
        tacccode
        taccname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblDoctor = /* GraphQL */ `
  query KeypinTblDoctor(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblDoctor(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tdocid
        tdocname
        tdocspes
        tdocaddres
        tdocdatestart
        tdoccity
        tdocphone
        tdocemail
        tdocactive
        tdoctype
        tdocdatereg
        tdocshare
        tdocstrno
        tdocsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tdocidTblDoctor = /* GraphQL */ `
  query TdocidTblDoctor(
    $tdocid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tdocidTblDoctor(
      tdocid: $tdocid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tdocid
        tdocname
        tdocspes
        tdocaddres
        tdocdatestart
        tdoccity
        tdocphone
        tdocemail
        tdocactive
        tdoctype
        tdocdatereg
        tdocshare
        tdocstrno
        tdocsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblWareHouse = /* GraphQL */ `
  query KeypinTblWareHouse(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblWareHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblWareHouse(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tiid
        twhid
        twhname
        twhstock
        twhlocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tiidTblWareHouse = /* GraphQL */ `
  query TiidTblWareHouse(
    $tiid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblWareHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tiidTblWareHouse(
      tiid: $tiid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tiid
        twhid
        twhname
        twhstock
        twhlocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblItemsPackage = /* GraphQL */ `
  query KeypinTblItemsPackage(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemsPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblItemsPackage(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tpid
        titpid
        tiid
        titpqty
        titpsatid
        titpsatname
        titpsatidcon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const titpidTblItemsPackage = /* GraphQL */ `
  query TitpidTblItemsPackage(
    $titpid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemsPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    titpidTblItemsPackage(
      titpid: $titpid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tpid
        titpid
        tiid
        titpqty
        titpsatid
        titpsatname
        titpsatidcon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblPatient = /* GraphQL */ `
  query KeypinTblPatient(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblPatient(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tpatid
        tpatcat
        tpatcode
        tpatname
        tpatgender
        tpatborn
        tpataddress
        tpatmother
        tpatphone
        tpatblood
        tpatcity
        tpatmarital
        tpatjob
        tpatfather
        tpatkkno
        tpatregno
        tpatemail
        tpataller
        tpatactive
        tpatsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tpatidTblPatient = /* GraphQL */ `
  query TpatidTblPatient(
    $tpatid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tpatidTblPatient(
      tpatid: $tpatid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tpatid
        tpatcat
        tpatcode
        tpatname
        tpatgender
        tpatborn
        tpataddress
        tpatmother
        tpatphone
        tpatblood
        tpatcity
        tpatmarital
        tpatjob
        tpatfather
        tpatkkno
        tpatregno
        tpatemail
        tpataller
        tpatactive
        tpatsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblCategory = /* GraphQL */ `
  query KeypinTblCategory(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblCategory(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tcid
        tcname
        tcrate
        tcnote
        tcactive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tcidTblCategory = /* GraphQL */ `
  query TcidTblCategory(
    $tcid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tcidTblCategory(
      tcid: $tcid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tcid
        tcname
        tcrate
        tcnote
        tcactive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblCashierUsers = /* GraphQL */ `
  query KeypinTblCashierUsers(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblCashierUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblCashierUsers(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tcuid
        tcuname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tcuidTblCashierUsers = /* GraphQL */ `
  query TcuidTblCashierUsers(
    $tcuid: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTblCashierUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tcuidTblCashierUsers(
      tcuid: $tcuid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tcuid
        tcuname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblUserConfig = /* GraphQL */ `
  query KeypinTblUserConfig(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblUserConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblUserConfig(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tucid
        tlogid
        tucprinter
        tucleft1
        tucleft2
        tucleft3
        tucleft4
        tucleft5
        tucleft6
        tucleft7
        tucleft8
        tucleft9
        tucleft10
        tucleft11
        tucleft12
        tucleft13
        tucright1
        tucright2
        tucright3
        tucright4
        tucright5
        tucright6
        tucright7
        tucright8
        tucright9
        tucright10
        tucright11
        tucright12
        tucright13
        tucpromo1
        tucpromo2
        tucpromo3
        tucpromo4
        tucpromo5
        tucpromo6
        tucpromo7
        tucpromo8
        tucpromo9
        tucpromo10
        tucpromo13
        tucexpired1
        tucexpired2
        tucexpired3
        tucexpired4
        tucexpired5
        tucexpired6
        tucexpired7
        tucexpired8
        tucexpired9
        tucexpired10
        tucexpired13
        tucdetail1
        tucdetail2
        tucdetail6
        tucdetail7
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tlogidTblUserConfig = /* GraphQL */ `
  query TlogidTblUserConfig(
    $tlogid: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblUserConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tlogidTblUserConfig(
      tlogid: $tlogid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keypinID
        keypin
        tucid
        tlogid
        tucprinter
        tucleft1
        tucleft2
        tucleft3
        tucleft4
        tucleft5
        tucleft6
        tucleft7
        tucleft8
        tucleft9
        tucleft10
        tucleft11
        tucleft12
        tucleft13
        tucright1
        tucright2
        tucright3
        tucright4
        tucright5
        tucright6
        tucright7
        tucright8
        tucright9
        tucright10
        tucright11
        tucright12
        tucright13
        tucpromo1
        tucpromo2
        tucpromo3
        tucpromo4
        tucpromo5
        tucpromo6
        tucpromo7
        tucpromo8
        tucpromo9
        tucpromo10
        tucpromo13
        tucexpired1
        tucexpired2
        tucexpired3
        tucexpired4
        tucexpired5
        tucexpired6
        tucexpired7
        tucexpired8
        tucexpired9
        tucexpired10
        tucexpired13
        tucdetail1
        tucdetail2
        tucdetail6
        tucdetail7
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblItemSale = /* GraphQL */ `
  query KeypinTblItemSale(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblItemSale(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keypinID
        keypin
        tisid
        tiud
        tpatid
        tisrecipe
        tdocid
        tisdate
        tisnoinvoice
        tispredisc
        tisdisc
        tisdp
        tiscard
        tiscardcash
        tiscash
        tischange
        tistype
        tisnote
        tisactive
        tistaxinvoice
        tisdeadline
        tisday
        tispretax
        tistax
        tiscategoryid
        tiscost
        tshid
        tispay
        tissync
        tisbank
        tisbankid
        tistotal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tisidTblItemSale = /* GraphQL */ `
  query TisidTblItemSale(
    $tisid: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tisidTblItemSale(
      tisid: $tisid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keypinID
        keypin
        tisid
        tiud
        tpatid
        tisrecipe
        tdocid
        tisdate
        tisnoinvoice
        tispredisc
        tisdisc
        tisdp
        tiscard
        tiscardcash
        tiscash
        tischange
        tistype
        tisnote
        tisactive
        tistaxinvoice
        tisdeadline
        tisday
        tispretax
        tistax
        tiscategoryid
        tiscost
        tshid
        tispay
        tissync
        tisbank
        tisbankid
        tistotal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tisactiveTblItemSale = /* GraphQL */ `
  query TisactiveTblItemSale(
    $tisactive: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tisactiveTblItemSale(
      tisactive: $tisactive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keypinID
        keypin
        tisid
        tiud
        tpatid
        tisrecipe
        tdocid
        tisdate
        tisnoinvoice
        tispredisc
        tisdisc
        tisdp
        tiscard
        tiscardcash
        tiscash
        tischange
        tistype
        tisnote
        tisactive
        tistaxinvoice
        tisdeadline
        tisday
        tispretax
        tistax
        tiscategoryid
        tiscost
        tshid
        tispay
        tissync
        tisbank
        tisbankid
        tistotal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keypinTblItemSaleDetail = /* GraphQL */ `
  query KeypinTblItemSaleDetail(
    $keypin: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemSaleDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keypinTblItemSaleDetail(
      keypin: $keypin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keypinID
        keypin
        tisdid
        tisid
        tiid
        tisdsoid
        tisdcode
        tisdtype
        tisdsatnama
        tisdqty
        tisdconvert
        tisdhb
        tisdhpp
        tisdhj
        tisdsubtotal
        tisdpredisc
        tisddisc
        tisdtotal
        tisdpackage
        tisdrecipe
        tisdtuslah
        tisdemba
        tisdnote
        tisdflax
        tisdflaxr
        tisdpriceid
        tisdpoint
        tisdname
        twhid
        tisdpricetype
        tisdsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tisidTblItemSaleDetail = /* GraphQL */ `
  query TisidTblItemSaleDetail(
    $tisid: String
    $sortDirection: ModelSortDirection
    $filter: ModelTblItemSaleDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tisidTblItemSaleDetail(
      tisid: $tisid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keypinID
        keypin
        tisdid
        tisid
        tiid
        tisdsoid
        tisdcode
        tisdtype
        tisdsatnama
        tisdqty
        tisdconvert
        tisdhb
        tisdhpp
        tisdhj
        tisdsubtotal
        tisdpredisc
        tisddisc
        tisdtotal
        tisdpackage
        tisdrecipe
        tisdtuslah
        tisdemba
        tisdnote
        tisdflax
        tisdflaxr
        tisdpriceid
        tisdpoint
        tisdname
        twhid
        tisdpricetype
        tisdsync
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
