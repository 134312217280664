import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./reusable/VMComponents/Assets/index.css";
import { CheckRoute } from "./views/default/Auth";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
// const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/default/Page404"));
const Page500 = React.lazy(() => import("./views/default/Page500"));
const Login = React.lazy(() => import("./views/default/Login"));
const SinkronData = React.lazy(() => import("./views/sinkron-data"));
const PenjualanObatKasirOffline = React.lazy(() =>
  import("./views/penjualan-obat-kasir-offline/index")
);
const Struk58 = React.lazy(() =>
  import("./views/penjualan-obat-kasir-offline/struk/struk58")
);
const Struk76 = React.lazy(() =>
  import("./views/penjualan-obat-kasir-offline/struk/struk76")
);
const StrukA4 = React.lazy(() =>
  import("./views/penjualan-obat-kasir-offline/struk/strukA4")
);

const StrukA5 = React.lazy(() =>
  import("./views/penjualan-obat-kasir-offline/struk/strukA5")
);

const ShiftPrint = React.lazy(() =>
  import("./views/penjualan-obat-kasir-offline/struk/shift-print")
);

class App extends Component {
  state = { checkData: "" };
  componentDidMount = async () => {
    const checkData = await CheckRoute();
    this.setState({
      checkData: checkData
    });
  };

  render() {
    const { checkData } = this.state;

    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/login/:token"
              name="Login"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/:domain/penjualan-obat-kasir-offline"
              name="Home"
              render={props => <PenjualanObatKasirOffline {...props} />}
            />
            <Route
              path="/:domain/sinkron-data"
              name="sinkron"
              render={props => <SinkronData {...props} />}
            />
            <Route
              path="/:domain/penjualan-obat-kasir-offline/struk58"
              // target="_blank"
              name="struk58"
              render={props => <Struk58 {...props} />}
            />
            <Route
              path="/:domain/penjualan-obat-kasir-offline/struk76"
              name="struk76"
              render={props => <Struk76 {...props} />}
            />
            <Route
              path="/:domain/penjualan-obat-kasir-offline/strukA4"
              name="strukA4"
              render={props => <StrukA4 {...props} />}
            />
            <Route
              path="/:domain/penjualan-obat-kasir-offline/strukA5"
              name="strukA5"
              render={props => <StrukA5 {...props} />}
            />
            <Route
              path="/:domain/penjualan-obat-kasir-offline/shift"
              name="shift-print"
              render={props => <ShiftPrint {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => {
                // console.log("checkData", checkData);
                return <Redirect to={checkData} {...props} />;
              }}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
