// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { loginWebjs, Todo2, masPasien, masDokter, masPoli, IncrementId, DeviceId, TblVersi, TblGroup, TblGroupAccess, getToken, TblIdentity, TblLogin, TblJadShf, TblShf, TblShif, TblAppConfig, TblItems, loginWebjs3, loginWebjs4, TblSync, TblAccount, TblDoctor, TblWareHouse, TblItemsPackage, TblPatient, TblCategory, TblCashierUsers, TblUserConfig, TblItemSale, TblItemSaleDetail, masObat, Todo, defaultType } = initSchema(schema);

export {
  loginWebjs,
  Todo2,
  masPasien,
  masDokter,
  masPoli,
  IncrementId,
  DeviceId,
  TblVersi,
  TblGroup,
  TblGroupAccess,
  getToken,
  TblIdentity,
  TblLogin,
  TblJadShf,
  TblShf,
  TblShif,
  TblAppConfig,
  TblItems,
  loginWebjs3,
  loginWebjs4,
  TblSync,
  TblAccount,
  TblDoctor,
  TblWareHouse,
  TblItemsPackage,
  TblPatient,
  TblCategory,
  TblCashierUsers,
  TblUserConfig,
  TblItemSale,
  TblItemSaleDetail,
  masObat,
  Todo,
  defaultType
};