import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";

/* Library for integration AWS AppSync */
import AWSAppSyncClient from "aws-appsync";
import { ApolloProvider, Subscription } from "react-apollo";
import { Rehydrated } from "aws-appsync-react";
import awsconfig from "./aws-exports";

/* Alternative library for Integration AWS AppSync */
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloLink } from 'apollo-link';
// import { createAuthLink } from 'aws-appsync-auth-link';
// import { createHttpLink } from 'apollo-link-http';
// import awsconfig  from './aws-exports';
// import ApolloClient from 'apollo-client';
// import { InMemoryCache, ApolloClient } from '@apollo/client';

/* Library AWS Amplify */
import Amplify from "@aws-amplify/core";
import Version from "./Version";

/* Amplify Init */
Amplify.configure(awsconfig);

React.icons = icons;
const conflictResolver = confRes => {
  const { mutation, mutationName, variables, data, retries } = confRes;
  // console.log("confRes", confRes);
  switch (mutationName) {
    case "UpdatePostMutation":
      return {
        ...variables,
        expectedVersion: data.version
      };
    default:
      return false;
  }
};

/* AppSync client instantiation */
export const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    // type: 'API_KEY',
    type: awsconfig.aws_appsync_authenticationType,
    apiKey: awsconfig.aws_appsync_apiKey
  },
  cacheOptions: {
    dataIdFromObject: obj => `${obj.__typename}:${obj.myKey}`
  }
  // conflictResolver: conflictResolver,
  // disableOffline: false,
  // offlineConfig: {
  //   callback: (err, succ) => {
  //     if (err) {
  //       const { mutation, variables } = err;

  //       console.info(`ERROR for ${mutation}`, err);
  //     } else {
  //       const { mutation, variables } = succ;

  //       console.info(`SUCCESS for ${mutation}`, succ);
  //     }
  //   }
  // }
});

/* Alternative AppSync client instantiation */
// const url = awsconfig.aws_appsync_graphqlEndpoint
// const region = awsconfig.aws_appsync_region
// const auth = {
//   // type: 'API_KEY',
//   type: awsconfig.aws_appsync_authenticationType,
//   apiKey: awsconfig.aws_appsync_apiKey
// }

// const link = ApolloLink.from([
//   createAuthLink({ url, region, auth }),
//   createHttpLink({ uri: url })
// ]);

// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache()
// });
// const qloginWebjs = gql`
// query {
//   LoginWebjs {
//     statusCode
//     body
//     events
//   }
// }`;

const WithProvider = () => {
  return (
    <ApolloProvider client={client}>
      <Rehydrated>
        <Provider store={store}>
          <App />
          <Version />
        </Provider>
      </Rehydrated>
    </ApolloProvider>
  );
};

ReactDOM.render(
  // <Provider store={store} >
  //   <App />
  // </Provider>,
  <WithProvider />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => {
    console.log("success register service worker");
  },
  onUpdate: () => {
    console.log("update service worker");
  }
});
// serviceWorker.unregister();
