import React, { useEffect, useState } from "react";
import { Subscription } from "react-apollo";
import { API, graphqlOperation } from "aws-amplify";
import Swal from "sweetalert2";
import { CTooltip } from "@coreui/react";
import { onCreateTblVersi, onUpdateTblVersi } from "./graphql/subscriptions";
import { getDeviceId, getIncrementId, getTblVersi } from "./graphql/queries";
import { DataStore } from "@aws-amplify/datastore";
import {
  createDeviceId,
  createIncrementId,
  createTblVersi,
  mLogV2,
  updateIncrementId,
  updateTblVersi
} from "./graphql/mutations";
import { DeviceId } from "./models";
import * as serviceWorker from "./serviceWorker";
import moment from "moment";

const Version = () => {
  let subscription = { unsubscribe: () => {}, _subscriber: () => {} };

  const [netInfo, setNetInfo] = useState(window.navigator.onLine);
  const status = netInfo ? " on" : " off";
  const vername = "v0.0.21";
  const versi = {
    id: 1,
    version: 21,
    vername: vername,
    vernote: `Vmedis Offline Versi (${vername}) sudah tersedia, Silakan klik tombol "Update Aplikasi" untuk melakukan update!`,
    verstatus: "force"
  };

  //---------Function Refresh---------//
  const refreshPage = () => {
    if (!!netInfo) {
      navigator.serviceWorker.ready
        .then(registration => {
          registration.unregister();
        })
        // .then(() => caches.delete("VmedisOffline"))
        .then(() => window.location.reload(false))
        .then(() =>
          serviceWorker.register({
            onSuccess: () => {
              console.log("success register service worker");
            },
            onUpdate: registration => {
              alert("New version available!  Ready to update?");
              if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: "SKIP_WAITING" });
              }
              window.location.reload();
            }
          })
        );
    } else {
      window.location.reload(false);
    }
  };

  const handleVersion = async () => {
    // subscription = API.graphql(
    //   graphqlOperation(onUpdateTblVersi)
    // ).subscribe({
    //   next: (value) => {
    //     console.log("onUpdateTblVersi next", value);
    //   }
    //   // error: error => {
    //   //   console.log("onUpdateTblVersi error", error);
    //   // }
    // });
    const getVer = await API.graphql(
      graphqlOperation(getTblVersi, {
        id: versi.id
      })
    );
    if (getVer?.data?.getTblVersi === null) {
      // Insert versi
      API.graphql(
        graphqlOperation(createTblVersi, {
          input: versi
        })
      );
      // Insert log versi
      API.graphql(
        graphqlOperation(createTblVersi, {
          input: { ...versi, ...{ id: `log-${versi.version}` } }
        })
      );
      return;
    }
    if (!!getVer?.data?.getTblVersi?.version !== versi.version) {
      // console.log(
      //   "getVer",
      //   getVer,
      //   getVer?.data?.getTblVersi?.version > versi.version,
      //   getVer?.data?.getTblVersi?.version < versi.version
      // );
      if (getVer?.data?.getTblVersi?.version > versi.version) {
        // Menampilkan popup
        Swal.fire({
          text: getVer?.data?.getTblVersi?.vernote,
          // title: "Versi : " + getVer?.data?.getTblVersi?.vername,
          icon: "info",
          showCancelButton: getVer?.data?.getTblVersi?.verstatus !== "force",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "Update Aplikasi",
          confirmButtonColor: "#2eb85c",
          cancelButtonText: "Nanti Saja",
          cancelButtonColor: "#e55353"
        }).then(result => {
          if (result.isConfirmed) {
            // do update
            refreshPage();
          }
        });
      } else if (getVer?.data?.getTblVersi?.version < versi.version) {
        // Update versi
        API.graphql(
          graphqlOperation(updateTblVersi, {
            input: versi
          })
        );
        // Insert log versi
        API.graphql(
          graphqlOperation(createTblVersi, {
            input: { ...versi, ...{ id: `log-${versi.version}` } }
          })
        );
      } else {
        // Do nothing
      }
      return;
    }
    if (!getVer?.data?.getTblVersi) {
      // Gagal check versi
      return;
    }
  };

  const handleApp = async () => {
    // Handling app
    const deviceid = localStorage.getItem("deviceid");
    if (!deviceid) {
      // Generate id
      const incid = await API.graphql(
        graphqlOperation(getIncrementId, { id: "DeviceId" })
      );
      // console.log("incid", incid);
      if (!incid?.data?.getIncrementId?.code) {
        // Jika belum ada data increment
        const dvid = await API.graphql(
          graphqlOperation(createDeviceId, {
            input: {
              code: `${"0".repeat(5)}1`,
              status: JSON.stringify({
                userAgent: navigator.userAgent
              })
            }
          })
        );
        // console.log("dvid 1", dvid);
        await API.graphql(
          graphqlOperation(createIncrementId, {
            input: {
              id: "DeviceId",
              name: "DeviceId",
              code: "1",
              refid: dvid?.data?.createDeviceId?.id
            }
          })
        );
        // Simpan local
        await saveLocal(DeviceId, dvid?.data?.createDeviceId, "code");
        localStorage.setItem("deviceid", dvid?.data?.createDeviceId?.id);
      } else {
        // Jika sudah ada
        const cinc = incid?.data?.getIncrementId?.code;
        const clength = cinc.length;
        const dvid = await API.graphql(
          graphqlOperation(createDeviceId, {
            input: {
              code: `${"0".repeat(6 - clength)}${cinc}`,
              status: JSON.stringify({
                userAgent: navigator.userAgent
              })
            }
          })
        );
        // console.log("dvid 2", cinc, dvid);
        await API.graphql(
          graphqlOperation(updateIncrementId, {
            input: {
              id: "DeviceId",
              name: "DeviceId",
              code: `${Number(cinc) + 1}`,
              refid: dvid?.data?.createDeviceId?.id
            }
          })
        );
        // Simpan local
        await saveLocal(DeviceId, dvid?.data?.createDeviceId, "code");
        localStorage.setItem("deviceid", dvid?.data?.createDeviceId?.id);
      }
    } else {
      // Simpan local
      const dvid = await API.graphql(
        graphqlOperation(getDeviceId, {
          id: deviceid
        })
      );
      if (!dvid?.data?.getDeviceId) {
        // ketika id salah maka hapus data localstorage
        localStorage.removeItem("deviceid");
        return handleApp();
      }
      // console.log("dvid 2", dvid);
      await saveLocal(DeviceId, dvid?.data?.getDeviceId, "code");
    }
  };

  const saveLocal = async (model, data, id) => {
    // Save local
    // console.log("data", data);
    const tDatastore = await DataStore.query(model, c =>
      c.code("eq", data[id])
    );
    if (!!data.createdAt) delete data.createdAt;
    if (!!data.updatedAt) delete data.updatedAt;
    if (!!data?.status) delete data.status;
    await DataStore.save(
      !tDatastore[0]
        ? new model(data)
        : model.copyOf(tDatastore[0], updated => {
            updated = data;
          })
    );
  };

  const handleContinues = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user?.app_id) {
      return;
    }
    const app_id = user?.app_id;
    const arrcnt = JSON.parse(localStorage.getItem(`arroffcon${app_id}`));
    if (!arrcnt || !arrcnt?.length) {
      return;
    } else {
      const pending = arrcnt.map(im => {
        const newData = {
          tiud: im.a,
          turl: "/penjualan-obat-v3",
          tnote: "Melanjutkan transaksi tanpa sinkron upload data.",
          tbefore: JSON.stringify({
            Upload: im.b
          }),
          tafter: JSON.stringify({
            Upload: im.c
          })
        };
        const DataSend = {
          input: {
            pin: app_id,
            data: JSON.stringify(newData)
          }
        };
        return API.graphql(graphqlOperation(mLogV2, DataSend));
      });
      Promise.all(pending).then(th => {
        const newarrcnt = arrcnt
          .map((im, index) => {
            if (th[index]?.data?.mLogV2?.statusCode === 200) {
              return;
            } else {
              return im;
            }
          })
          .filter(fl => fl !== undefined);
        if (newarrcnt.length > 0) {
          localStorage.setItem(`arroffcon${app_id}`, JSON.stringify(newarrcnt));
        } else {
          localStorage.removeItem(`arroffcon${app_id}`);
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("online", e => e.isTrusted && setNetInfo(true));
    window.addEventListener("offline", e => e.isTrusted && setNetInfo(false));
    if (netInfo) {
      (async () => {
        await handleVersion();
        await handleApp();
        await handleContinues();
      })();
    }

    return () => {
      window.removeEventListener(
        "online",
        e => e.isTrusted && setNetInfo(true)
      );
      window.removeEventListener(
        "offline",
        e => e.isTrusted && setNetInfo(false)
      );
      subscription.unsubscribe();
    };
  }, [netInfo]);
  return (
    <div className="version no-print">
      {/* versi mr vDD.MM.YYYY mr(deploy number) */}
      <CTooltip CTooltip content={`${status}line `} placement="top">
        <div className={`netinformation${status} m-1`} />
      </CTooltip>
      {versi.vername}
    </div>
  );
};

export default Version;
